import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import useAuth from '@src/hooks/useAuth';

import * as S from './styles';

export interface ErrorTemplateProps {
  errorType: '404' | '500';
}

const ErrorTemplate = ({errorType}: ErrorTemplateProps) => {
  const {homeUrl} = useAuth();

  const errorDescription = errorType === '404' ? 'This page could not be found.' : 'Something went wrong.';

  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <Link href={homeUrl}>
          <a>
            <Image src="/img/logo.svg" alt="Riiid for Classrooms logo" width={100} height={30} />
          </a>
        </Link>
      </S.LogoWrapper>
      <S.BodyWrapper>
        <S.ErrorMessageWrapper>
          <Image src="/img/illustrations/error.svg" alt="Cloud Image" width={586} height={280} />
          <S.ErrorText>{errorType} Error</S.ErrorText>
        </S.ErrorMessageWrapper>
        <S.ErrorDescription>{errorDescription}</S.ErrorDescription>
        {errorType === '404' && (
          <Link passHref href={homeUrl}>
            <S.Button asLink>Go Home</S.Button>
          </Link>
        )}
      </S.BodyWrapper>
    </S.Wrapper>
  );
};
export default ErrorTemplate;
