import React from 'react';
import Head from 'next/head';

import Error from '@src/templates/Error';

const Page404 = () => {
  return (
    <>
      <Head>
        <title>Page Not Found - Riiid for Classrooms</title>
      </Head>
      <Error errorType="404" />
    </>
  );
};

export default Page404;
