import styled from '@emotion/styled';
import {css} from '@emotion/react';

import DefaultButton from '@src/components/atoms/Button';

export const Wrapper = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    background-color: ${theme.colors.mainBg};
  `}
`;

export const LogoWrapper = styled.div`
  width: 100%;
  max-width: 1024;
  padding: 50px 70px;
  display: flex;
  justify-content: flex-start;
`;

export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ErrorMessageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  height: 300px;
  text-align: center;
  & > img {
    position: absolute;
    top: 0;
  }
`;

export const ErrorText = styled.p`
  ${({theme}) => css`
    position: absolute;
    color: ${theme.colors.gray3};
    font-size: ${theme.font.sizes.error};
    font-weight: lighter;
    margin-bottom: 50px;
    bottom: 0;
  `}
`;

export const ErrorDescription = styled.p`
  ${({theme}) => css`
    color: ${theme.colors.gray8};
    font-size: ${theme.font.sizes.xlarge};
    margin-bottom: 20px;
  `}
`;

export const Button = styled(DefaultButton)`
  width: 200px;
`;
